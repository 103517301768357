import { useRef, useState } from "react";
import rickRoll from "./assets/rick_roll.mp4";
import { AnimatePresence, motion } from "framer-motion";
function App() {
  const videoRef = useRef<(HTMLVideoElement | null)[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const playVideo = () => {
    setIsVisible(true);
    if (videoRef.current) {
      if (isIos()) {
        videoRef.current[0]?.play();
      } else {
        videoRef.current.map((ref) => ref?.play());
      }
    }
  };
  const items = [];
  for (let i = 0; i < 20; i++) {
    items.push(
      <li>
        <video
          key={i}
          ref={(el) => (videoRef.current[i] = el)}
          width={560}
          height={315}
          controls
        >
          <source src={rickRoll} type="video/mp4" />
        </video>
      </li>
    );
  }
  const variants = {
    initial: { y: 100, opacity: 0 },
    animate: {
      y: 0,
      opacity: [1, 0, 1],
      transition: {
        type: "spring",
        opacity: {
          duration: 2,
          repeat: Infinity,
          repeatType: "loop",
          delay: 2,
        },
      },
    },
  };
  return (
    <main className="min-h-screen w-full bg-black flex flex-col justify-center items-center text-white p-4 text-center">
      <AnimatePresence>
        {!isVisible ? (
          <>
            <motion.div
              initial={{ y: -100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: "spring" }}
              className="flex flex-col items-center"
            >
              <h1 className="text-4xl mb-6">Hey there! 👋</h1>
              <p className="mb-4">
                I'm a software developer with 3 years of professional experience
              </p>
            </motion.div>
            <motion.span
              variants={variants}
              initial="initial"
              animate="animate"
              className=" border-slate-600 border p-2 cursor-pointer"
              onClick={() => playVideo()}
            >
              View my journey 👈
            </motion.span>
          </>
        ) : (
          <></>
        )}

        <ul className={`${isVisible ? "block" : "hidden"}`}>
          {isIos() ? items[0] : items}
        </ul>
      </AnimatePresence>
    </main>
  );
}

export default App;

function isIos() {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream
  );
}
